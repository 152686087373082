<template>
  <div class="w-full flex flex-col">
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-4 text-2xl mb-2">Facturación</h3>
    <h4 class="text-start mt-1 mb-4 text-gray-500 text-lg">Listado</h4>
    <section class="w-full bg-white p-2">
      <div class="w-full flex justify-between">
        <label>Filtro</label>
        <button @click.stop="handleToggle">
          <minus-icon v-if="isToggle" class="w-4 h-4" />
          <plus-icon v-else class="w-4 h-4" />
        </button>
      </div>
      <div class="w-full flex flex-col">
        <b-form @submit="applyFilters">
          <div>
            <div class="flex grid grid-cols-3 gap-4 mt-4" v-if="isToggle">
              <input-field
                idInput="keyWord"
                idFormGroup="keyWord-group"
                type="text"
                label="Palabra Clave"
                classLabel="mt-1"
                classInput="input-filter"
                v-model="filterForm.keyWord"
              />
              <select-input
              idInput="clientSelect"
              idFormGroup="client-group"
              label="Cliente"
              v-model="filterForm.client"
              :options="clientListOptions"
              />
            </div>
          </div>
        </b-form>
      </div>
    </section>
    <section class="w-full bg-white p-2 mt-4">
      <div class="w-full flex justify-start">
        <label class="mb-4">Clareo</label>
      </div>
      <table-clear-package :packages="packages" />
    </section>
  </div>
</template>

<script>
import InputField from '@/components/InputField.vue';
import SelectInput from '@/components/SelectInput.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';
import TableClearPackage from '@/components/TableClearPackage.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';

export default {
  name: 'ClearPackage',
  components: {
    PlusIcon,
    MinusIcon,
    InputField,
    SelectInput,
    TableClearPackage,
    BreadCrumbs,
  },
  data() {
    return {
      isToggle: true,
      filterForm: {
        keyWord: '',
        filterBySelect: null,
        operatorSelect: null,
        client: null,
        agent: null,
        state: '',
        payment: '',
        sortBy: null,
        office: null,
      },
      routes: [
        { name: 'Inicio', path: '/' },
      ],
      clientListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Alejandro Gonzalez', value: 'alejandro_gonzalez' },
      ],
      packages: [
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
      ],
    };
  },
  methods: {
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
    applyFilters() {
      // Logic here
    },
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
  },
  mounted() {
    this.loadRoutesBreadCrumbs(this.$router);
  },
};
</script>

<style scoped>
</style>
